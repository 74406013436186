import React from 'react';
import Footer from '../components/Footer';
import Hero from '../components/Hero';
import NavBar from '../components/Navbar/NavBar';

const ComingSoon = () => {
    return (
        <section style={styles.container}>
            <div style={styles.content}>
                <h2 style={styles.heading}>Prepare for the Future</h2>
                <p style={styles.subheading}>
                    At <strong>Praxes</strong>, we’re building immersive, hands-on job simulation experiences 
                    designed to bridge the gap between education and real-world careers. Our next set of 
                    cutting-edge modules is on its way!
                </p>
                <p style={styles.comingSoon}>Coming Soon</p>
            </div>
        </section>
    );
};

const styles = {
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        padding: '4rem 2rem',
        background: 'linear-gradient(90deg, #f8fafc, #eaf0f6)',
        borderRadius: '8px',
        margin: '2rem 0',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    },
    content: {
        maxWidth: '800px',
    },
    heading: {
        fontSize: '2.5rem',
        fontWeight: 'bold',
        color: '#333',
        marginBottom: '1rem',
    },
    subheading: {
        fontSize: '1.2rem',
        color: '#555',
        lineHeight: '1.6',
        marginBottom: '2rem',
    },
    comingSoon: {
        fontSize: '1.5rem',
        fontWeight: 'bold',
        color: '#007BFF',
        marginTop: '1rem',
    },
};

const Home = () => {
    return (
        <>
            <NavBar />
            <Hero />
            <ComingSoon />
            <Footer />
        </>
    );
};

export default Home;
