import React from 'react';
import './index.css';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';
import Home from './components/Home';
import { useDocTitle } from './components/CustomHook';

function App() {

  useDocTitle("Praxes");

  return (
    <>
      <Router>
          <Routes>
            <Route path="/" element={<Home />} />
          </Routes>
      </Router>
    </>
  );
}


export default App;
