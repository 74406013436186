import React from 'react';
import { Link } from 'react-router-dom';
import heroImg from '../images/innovation.png';

const Hero = () => {
  return (
    <div className="hero" id="hero">
      <div className="m-auto overflow-hidden mx-4 pt-16 lg:pt-24 p-4 md:p-12 h-5/6" data-aos="zoom-in">
        <div className="flex flex-col lg:flex-row py-8 justify-between items-center text-center lg:text-left">
          {/* Text Section */}
          <div className="lg:w-1/2 flex flex-col justify-center" data-aos="zoom-in" data-aos-delay="200">
            <h1 className="mb-6 md:text-5xl text-3xl font-extrabold text-gray-800 leading-tight">
              Build Job-Ready Skills with Real-World Simulations
            </h1>
            <p className="mb-6 text-lg md:text-xl text-gray-600 font-medium">
              Experience industry-specific projects designed to prepare you for the future. 
              Gain practical expertise, showcase your abilities, and take the next step in your career.
            </p>
            <Link 
              to="/" 
              className="bg-green-600 hover:bg-blue-700 text-white px-6 py-3 rounded-lg text-lg font-semibold shadow-lg transition duration-300 ease-in-out"
            >
              Get Started
              <svg 
                className="w-5 h-5 ml-2 inline-block" 
                xmlns="http://www.w3.org/2000/svg" 
                viewBox="0 0 20 20" 
                fill="currentColor"
              >
                <path 
                  fillRule="evenodd" 
                  d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" 
                  clipRule="evenodd" 
                />
              </svg>
            </Link>
          </div>

          {/* Image Section */}
          <div 
            className="flex lg:justify-end w-full lg:w-1/2 mt-8 lg:mt-0" 
            data-aos="fade-up" 
            data-aos-delay="500"
          >
            <img 
              alt="Digital skills illustration" 
              className="rounded-lg w-full lg:w-auto max-w-lg mr-4" 
              style={{ backgroundColor: 'transparent', display: 'block' }} 
              src={heroImg} 
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
