import React, { useState, useEffect } from 'react';
import { HashLink } from 'react-router-hash-link';
import Logo from '../../images/logo.png';

const NavBar = () => {
    const [top, setTop] = useState(!window.scrollY);

    useEffect(() => {
        const scrollHandler = () => {
            window.pageYOffset > 10 ? setTop(false) : setTop(true)
        };
        window.addEventListener('scroll', scrollHandler);
        return () => window.removeEventListener('scroll', scrollHandler);
    }, [top]);

    return (
        <nav className={`fixed top-0 w-full z-30 transition duration-300 ease-in-out mb-16 ${!top ? 'bg-white shadow-md' : 'bg-white'}`}>
            <div className="flex flex-row justify-between items-center py-2 px-4">
                <div className="flex flex-row justify-center md:px-12 md:mx-12 items-center text-center font-semibold">
                    <HashLink smooth to="/">
                        <img src={Logo} alt="Website Logo" className="h-16 w-30 relative left-[-40px]" />
                    </HashLink>
                </div>
            </div>
        </nav>
    );
};

export default NavBar;
